body {
	font-family: 'Gabarito', sans-serif;
	padding: 0;
	margin: 0;
}

.btn-primary,
.btn-blue {
	background-color: #3737fa !important;
	border-color: #3737fa !important;
}

.text-primary {
	color: #3737fa !important;
}

:root {
	--bs-primary: #3737fa;
	--bs-blue: #3737fa;
	--bs-black: #151515;
	--bg-light-gray: #f2f2f2;
	--bg-filter: #fcfcfc;
	--filter-color: #686868;
	--filter-link-color: #5e5e5e;
	--bg-filter-active: #7272ff;
	--bg-offer: #339239;
	--bg-products: #f6f6f6;
	--bg-parts-single-product: #f8f8f8;
	--tabs-color: #686868;
	--placeholder-color: #878787;
	--bs-primary-rgb: 55, 55, 250;
}