/*
Theme name: BAL Solutions
*/

/*@import url(./crm.css);*/
@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');



body::-webkit-scrollbar-track {
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 12px;
	background-color: #fff;
}

body::-webkit-scrollbar {
	width: 12px;
	background-color: #fff;
	padding: 2px;
}

body::-webkit-scrollbar-thumb {
	/* border-radius: 10px; */
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
	background-color: var(--bs-primary);
	border: 2px solid #fff;
}

.btn-primary {
	--bs-btn-color: #fff;
	--bs-btn-bg: var(--bs-primary);
	--bs-btn-border-color: var(--bs-primary);
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #2f2fd5;
	--bs-btn-hover-border-color: #2c2cc8;
	--bs-btn-focus-shadow-rgb: 85, 85, 251;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #2c2cc8;
	--bs-btn-active-border-color: #2929bc;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 21, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: var(--bs-primary);
	--bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-outline-primary {
	--bs-btn-color: #3737fa !important;
	--bs-btn-border-color: #3737fa !important;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #3737fa !important;
	--bs-btn-hover-border-color: #3737fa !important;
	--bs-btn-focus-shadow-rgb: 55, 55, 250;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #3737fa !important;
	--bs-btn-active-border-color: #3737fa !important;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 21, 0.125);
	--bs-btn-disabled-color: #3737fa !important;
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: #3737fa !important;
	--bs-gradient: none;
}

.btn-link-outline-light {
	--bs-btn-color: #fff;
	--bs-btn-bg: transparent;
	--bs-btn-border-color: #fff;
	--bs-btn-hover-color: #151515;
	--bs-btn-hover-bg: #fff;
	--bs-btn-hover-border-color: #fff;
	--bs-btn-focus-shadow-rgb: 255, 255, 255;
	--bs-btn-active-color: #151515;
	--bs-btn-active-bg: #fff;
	--bs-btn-active-border-color: #fff;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 21, 0.125);
	--bs-btn-disabled-color: none;
	--bs-btn-disabled-bg: none;
	--bs-btn-disabled-border-color: none;
}

.home.banner {
	background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 50%), url('/public/images/bg-home-2.png') no-repeat center fixed;
	background-size: cover;
}

.btn-link-primary {
	--bs-btn-color: #fff;
	--bs-btn-bg: #3737fa;
	--bs-btn-border-color: #3737fa;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #2f2fd5;
	--bs-btn-hover-border-color: #2c2cc8;
	--bs-btn-focus-shadow-rgb: 85, 85, 251;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #2c2cc8;
	--bs-btn-active-border-color: #2929bc;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(21, 21, 21, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: #3737fa;
	--bs-btn-disabled-border-color: #3737fa;
}

.badge {
	transition: 0.2s all ease;
}

.badge:hover {
	transform: scale(1.05);
	transition: 0.2s all ease;
}

.badge a {
	text-decoration: none !important;
}

.badge.bg-primary {
	background-color: #cfe2ff !important;
	color: var(--bs-primary);
}

.badge.bg-secondary {
	background-color: #f8f9fa !important;
	color: var(--bs-secondary);
}

.badge.bg-success {
	background-color: #d1e7dd !important;
	color: var(--bs-success);
}

.badge.bg-warning {
	background-color: #fff3cd !important;
	color: var(--bs-warning);
}

.badge.bg-danger {
	background-color: #f8d7da !important;
}

.badge.bg-primary a {
	color: var(--bs-primary) !important;
}

.badge.bg-secondary a {
	color: var(--bs-secondary) !important;
}

.badge.bg-success a {
	color: var(--bs-success) !important;
}

.badge.bg-warning a {
	color: var(--bs-warning) !important;
}

.badge.bg-danger a {
	color: var(--bs-danger) !important;
}

.badge.bg-pink {
	background-color: #f7d6e6 !important;
}

.badge.bg-purple {
	background-color: #e2d9f3 !important;
}

.badge.bg-indigo {
	background-color: #e0cffc !important;
}

.badge.bg-orange {
	background-color: #ffe5d0 !important;
}

.badge.bg-cyan {
	background-color: #cff4fc !important;
}

.badge.bg-teal {
	background-color: #d2f4ea !important;
}

section {
	overflow: hidden;
}

.form-check-input:checked {
	background-color: var(--bs-primary);
	border-color: var(--bs-primary);
}

* {
	font-family: 'Gabarito', sans-serif;
	font-weight: 400;
}

h1 {
	font-weight: 900;
	font-size: calc(1.75rem + 1.5vw);
}

h2,
h3,
h5 {
	font-weight: bold;
}

.banner {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 220px;
	color: #ffffff;
}

.banner.nosotros {
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%), url('/public/images/nosotros.png');
	height: 400px;
	background-position-y: bottom;
	font-weight: 900 !important;
}

.banner.contacto {
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%), url('/public/images/contact-us-banner-1024x273.png');
	height: 350px;
	font-weight: 900;
}

.banner.servicios {
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%), url('/public/images/services-banner-1024x335.webp');
	height: 300px !important;
}

.banner.productos {
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%), url('/public/images/contact-us-banner-1024x273.png');
}

.banner .container-fluid {
	height: 100%;
}

.banner .container-fluid .row {
	height: 100%;
}

.banner .container-fluid .row .content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.swiper.navigation {
	padding: 0 4rem;
}

.swiper.navigation .swiper-slide {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
}

.swiper.navigation .swiper-slide .card {
	border: none;
}

.swiper.navigation .swiper-slide .card .card-img-top {
	border-radius: 12px 12px 0 0;
}

.swiper.navigation .swiper-slide .card .card-body {
	box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.1607843137);
}

.swiper.navigation .swiper-slide .card .card-body .card-link {
	color: var(--bs-black);
	text-decoration: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.swiper.navigation .swiper-slide .card .card-body .card-link p {
	margin-bottom: 0;
	max-width: 90%;
	font-weight: 600;
	height: 24px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.swiper.navigation .swiper-slide .card .card-body .card-link i {
	color: var(--bs-primary);
}

.swiper.navigation .slide-navigator {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper.navigation .slide-navigator button {
	padding: 0.4rem 0.7rem;
	border-radius: 100%;
	border-width: 2px;
}

.swiper.navigation .slide-navigator button i {
	font-size: 25px;
}

.swiper.navigation .slide-navigator.next {
	right: 0;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 30%);
}

.swiper.navigation .slide-navigator.prev {
	left: 0;
	background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 30%);
}

ul {
	list-style-type: none;
	margin-bottom: 0;
}

.services ul li {
	padding: 1rem;
	/*border-bottom: 1px solid var(--bs-gray-300);*/
}

.services.specialization ul {
	list-style-type: none;
	list-style-image: url('/public/images/list-dot.svg');
}

form .form-control {
	border: none;
	border-radius: 0;
	border-right: 1px solid var(--bs-gray-300);
	border-bottom: 1px solid var(--bs-gray-300);
	padding-left: 10px;
	padding-right: 0;
}

form .form-control:focus {
	box-shadow: none;
}

form button[type='submit'] {
	padding-left: 2rem;
	padding-right: 2rem;
}

.form-control.contact-form {
	border: none;
	border-bottom: 1px solid #e0e0e0;
	border-right: 1px solid #e0e0e0;
	border-radius: 0%;
}

.form-control.contact-form:focus {
	box-shadow: none;
	border-bottom: 1px solid var(--bs-primary);
	border-right: 1px solid var(--bs-primary);
}


.price {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 1rem;
}

.price span {
	background-color: var(--bg-offer);
	padding: 0.15rem 0.5rem;
	border-radius: 26px;
	color: #ffffff;
	margin-right: 15px;
}

.price p {
	margin-bottom: 0;
	font-size: 18px;
	color: var(--bs-primary);
	margin-right: 15px;
}

.price del {
	color: var(--bs-gray-600);
	font-size: 14px;
}

.shop-titles {
	font-weight: bold;
	font-size: 20px;
	color: var(--bs-primary);
}

.order-summary .title {
	border-bottom: 1px solid var(--bs-primary);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.order-summary .summary div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.order-summary .summary div p:last-of-type {
	font-weight: 600;
}

.order-summary .total {
	border-top: 1px solid var(--bs-primary);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.order-summary .total p {
	margin-bottom: 0;
}

.order-summary .total p:last-of-type {
	font-weight: 600;
}

.navbar {
	padding: 0;
	box-shadow: 0px 3px 35px rgba(0, 0, 0, 0.0901960784);
}

.navbar .gallery {
	font-size: 0.9rem;
}

.navbar .gallery a {
	color: #ffffff;
	text-decoration: none;
	margin: 0 1rem;
	font-weight: bold;
}

.navbar .gallery a:hover {
	color: shade-color(#ffffff, 10%);
}

.navbar .nav-link {
	font-size: 1rem;
	margin: 0 1.5rem;
	color: var(--bs-black);
	transition: 0.3s all ease-in;
}

.navbar .nav-link:hover {
	color: var(--bs-primary) !important;
	transition: 0.3s all ease-in;
}

.navbar .nav-link:hover {
	color: tint-color(var(--bs-black), 20%);
}

.navbar .nav-link.btn-blue {
	background-color: var(--bs-primary);
	color: #ffffff;
	margin-right: 0;
}

.navbar .nav-link.btn-blue:hover {
	background-color: #2f2fd5;
	color: var(--bs-white) !important;
}

.navbar .navbar-toggler {
	border: none;
}

footer {
	background-color: #ffffff;
	box-shadow: 0px 3px 35px rgba(0, 0, 0, 0.1019607843);
	padding: 1.5rem 0;
}

footer .container {
	display: flex;
	justify-content: space-between;
}

footer .container .info {
	display: flex;
	align-items: center;
}

footer .container .info p {
	margin-left: 15px;
	font-size: 15px;
}

footer .container .social {
	display: flex;
	align-items: center;
}

footer .container .social i {
	margin-left: 5px;
	font-size: 20px;
	color: var(--bs-primary);
}

img[alt='logo'] {
	width: 250px;
}

.home.banner {
	color: #ffffff;
	height: calc(100vh - 110px);
	position: relative;
	overflow-y: hidden;
	background-color: rgba(0, 0, 0, 0.75);
}

.home.banner .container {
	height: 100%;
}

.home.banner .container .row {
	height: 100%;
	justify-content: center;
	align-items: center;
}

.home.banner .container .row>div {
	padding: 0 4rem;
}

.home.banner .container .row>div h1 {
	letter-spacing: 2.75px;
}

.home.banner .container .row>div svg[alt='isologo'] {
	width: 70px;
	height: 70px;
}

.home.video-banner {
	color: #ffffff;
	height: calc(100vh - 110px);
	position: relative;
	overflow-y: hidden;
	background-color: rgba(0, 0, 0, 0.75);
}

.home.video-banner video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	aspect-ratio: 16/9;
	z-index: -1;
}

.home.video-banner .container {
	height: 100%;
}

.home.video-banner .container .row {
	height: 100%;
	justify-content: center;
	align-items: center;
}

.home.video-banner .container .row>div {
	padding: 0 4rem;
}

.home.video-banner .container .row>div h1 {
	letter-spacing: 2.75px;
}

.home.video-banner .container .row>div svg[alt='isologo'] {
	width: 70px;
	height: 70px;
}

.home.about-us {
	margin: 4rem 0;
}

.home.about-us .container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.home.about-us .container-fluid .mission {
	padding-left: 10rem;
	padding-right: 6rem;
	margin: auto 0;
}

.home.about-us .container-fluid .mission h2 svg[alt='asterisk'] {
	width: 2rem;
	height: 2rem;
}

.home.about-us .container-fluid .mission p:first-of-type {
	margin-bottom: 4rem;
}

.home.about-us .container-fluid .mission-image {
	margin: 0;
	padding: 0;
}

.home.about-us .container-fluid .mission-image img {
	border-radius: 30px 0px 0px 30px;
}

.home.about-us .container-fluid .mission-image img {
	width: 100%;
}

.home.products {
	margin: 4rem 0;
	background-color: rgba(242, 242, 242, 0.28);
	border-radius: 95px 95px 0 0;
}

.home.products .container {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.home.products .container .title {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 2rem;
}

.home.products .container .title h1 {
	flex: 0;
	max-width: 90%;
	text-align: center;
	white-space: nowrap;
	padding: 0 2rem;
}

.home.products .container .title hr {
	flex: 1;
	margin-bottom: 0;
}

.home.products .container .product-tabs {
	padding-right: 4rem;
	padding-left: 0;
}

.home.products .container .product-tabs .nav .nav-item {
	margin-bottom: 1rem;
	width: fit-content;
	text-align: end;
	width: 60%;
	transition: all 0.15s ease-out;
}

.home.products .container .product-tabs .nav .nav-item:has(.nav-link.active) {
	width: 100%;
	max-width: 100%;
}

.home.products .container .product-tabs .nav .nav-item .nav-link {
	background: #ffffff;
	color: var(--bs-black);
	padding: 0.8rem;
	width: 100%;
	text-align: end;
}

.home.products .container .product-tabs .nav .nav-item .nav-link.active {
	background: var(--bs-primary);
	color: #ffffff;
	text-align: start;
}

.home.products .container .product-tabs .nav .nav-item .nav-link:hover {
	background-color: var(--bs-primary);
	color: #ffffff;
}

.home.products .container .product-image {
	padding-right: 0;
	padding-left: 4rem;
}

.home.products .container .product-image .tab-content {
	background-color: #ffffff;
	height: 100%;
	border-radius: 19px;
	box-shadow: 0px 3px 34px rgba(0, 0, 0, 0.0705882353);
	padding: 4rem;
}

.home.products .container .product-image .tab-content .tab-pane {
	height: 100%;
}

.home.products .container .product-image .tab-content .tab-pane.active {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tab-pane-overlay {
	opacity: 0;
	background-color: #2929bc57;
	top: 0px;
	left: 0px;
	right: 0px;
	border: 0px;
	border-radius: 20px;
}

.tab-content:hover .tab-pane .tab-pane-overlay {
	opacity: 1;
}

.home.news {
	margin: 4rem 0;
}

.home.news .title {
	margin-bottom: 2rem;
}

.home.weg {
	margin: 4rem 0;
}

.home.weg img {
	width: 120px;
	margin-bottom: 2rem;
}

.home.weg h2 {
	margin-bottom: 2rem;
}

.home.weg p {
	width: 50%;
	margin: 0 auto 2rem auto;
}

.home.services {
	margin: 4rem 0;
}

.home.services .title img {
	width: 100%;
	border-radius: 30px;
}

.home.services .carousel {
	max-height: 100%;
}

.home.services .carousel .swiper {
	max-height: 75%;
	height: 75%;
}

.home.services .carousel .swiper .swiper-wrapper {
	padding-left: 4rem;
}

.home.services .carousel .swiper .swiper-wrapper .swiper-slide p {
	width: 80%;
}

.home.services .carousel .swiper .swiper-pagination {
	left: 0;
	right: inherit;
}

.home.services .carousel .swiper .swiper-pagination .swiper-pagination-bullet {
	background: transparent;
	border: 1px solid var(--bs-primary);
	width: 1rem;
	height: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.home.services .carousel .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active .bullet {
	display: block;
}

.home.services .carousel .swiper .swiper-pagination .swiper-pagination-bullet .bullet {
	display: none;
	background: var(--bs-primary);
	border: 1px solid var(--bs-primary);
	width: 0.6rem;
	height: 0.6rem;
	border-radius: 100%;
}

.home.contact-banner {
	background-color: var(--bs-primary);
	padding: 4rem 0;
}

.home.contact-banner .title {
	display: flex;
	align-items: flex-start;
}

.home.contact-banner .title svg {
	margin-top: 0.5rem;
	margin-right: 1rem;
}

.home.contact-banner button {
	display: block;
	margin: 0 auto;
	color: var(--bs-primary);
	background-color: #ffffff;
}

.home.contact-us .form {
	padding: 6rem 4rem;
}

.home.contact-us .form p {
	margin-bottom: 4rem;
}

.home.map {
	margin-bottom: -6px;
}

.about-us.about-us-cards .row .cards {
	display: flex;
	justify-content: center;
	align-items: baseline;
	flex-wrap: wrap;
}

.about-us.about-us-cards .row .cards .card {
	border: none;
	text-align: center;
	box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1607843137);
	border-radius: 20px;
	position: relative;
	margin-right: 2rem;
}

.about-us.about-us-cards .row .cards .card svg {
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	margin-bottom: -60px;
	width: 110px;
}

.about-us.about-us-cards .row .cards .card .card-body {
	padding-top: 4rem;
}

.about-us.about-us-cards .row .cards .card .card-body .title {
	color: var(--bs-primary);
	margin-bottom: 1rem;
}

.about-us.about-us-cards .row .cards .card:last-of-type {
	margin-right: 0;
}

.about-us.description {
	background-color: var(--bs-primary);
	color: #ffffff;
	padding: 4rem 0;
	text-align: center;
}

.about-us.description p {
	margin-bottom: 0;
	font-size: 20px;
}

.about-us.mission-vision {
	margin: 4rem 0;
}

.about-us.mission-vision .content {
	display: flex;
	align-items: flex-start;
	padding: 0 4rem;
}

.about-us.mission-vision .content svg {
	color: var(--bs-primary);
	width: 30px;
	height: 30px;
	margin-top: 5px;
	margin-right: 1rem;
}

.about-us.mission-vision .content h2 {
	color: var(--bs-primary);
	margin-bottom: 2rem;
}

.services.carousel {
	margin: 4rem 0;
}

.services.specialization {
	margin: 4rem 0;
}

.services.specialization .title {
	color: var(--bs-primary);
	margin-bottom: 2rem;
}

.services.specialization .list {
	display: flex;
	align-items: center;
}

.single-service.description {
	margin: 4rem 0;
}

.single-service.description .image {
	padding-right: 2rem;
}

.single-service.description .image img {
	width: 100%;
	border-radius: 12px;
}

.single-service.description .content {
	padding-left: 2rem;
	margin: auto 0;
}

.single-service.description .content h1 {
	margin-bottom: 2rem;
}

.single-service.description .content p {
	margin-bottom: 2rem;
}

.single-service.details {
	margin: 4rem 0;
}

.single-service.details .title {
	color: var(--bs-primary);
	border-bottom: 1px solid rgba(55, 55, 250, 0.3);
}

.single-service.details .content {
	padding: 0 4rem;
}

.single-service.details .content div {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 2rem;
}

.single-service.details .images {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 4rem;
	gap: 3.3333%;
}

.single-service.details .images img {
	border-radius: 12px;
	width: 31%;
	margin-bottom: 1rem;
}

.contact-us {
	margin: 4rem 0;
}

.contact-us .title {
	color: var(--bs-primary);
	border-bottom: 1px solid var(--bs-primary);
	margin-bottom: 2rem;
}

.contact-us .form {
	padding-right: 4rem;
}

.contact-us .form p {
	font-weight: bold;
	margin-bottom: 2rem;
}

.contact-us .image img {
	height: 500px;
	object-fit: cover;
}

.assistance {
	margin: 4rem 0;
}

.assistance .title {
	color: var(--bs-primary);
	border-bottom: 1px solid var(--bs-primary);
	margin-bottom: 2rem;
}

.assistance .form {
	padding-right: 4rem;
}

.assistance .form p {
	font-weight: bold;
	margin-bottom: 2rem;
}

.assistance .image {
	display: flex;
	align-items: center;
}

.assistance .image img {
	border-radius: 12px 0 0 12px;
}

.terms {
	margin: 4rem 0;
}

.terms .title {
	display: flex;
	color: var(--bs-primary);
	border-bottom: 1px solid var(--bs-primary);
	margin-bottom: 2rem;
}

.terms .title svg {
	margin-right: 1rem;
	margin-top: 3px;
	width: 30px;
	height: 30px;
}

.products.family-filter {
	background-color: transparent;
	margin-top: -40px;
}

.accordion-item:first-of-type {
	border-top-left-radius: 12px !important;
	border-top-right-radius: 12px !important;
}

.accordion-item:first-of-type>.accordion-header .accordion-button {
	border-top-left-radius: 12px !important;
	border-top-right-radius: 12px !important;
}

.accordion-item:last-of-type {
	border-bottom-right-radius: 12px !important;
	border-bottom-left-radius: 12px !important;
}

.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
	border-bottom-right-radius: 12px !important;
	border-bottom-left-radius: 12px !important;
}

.products.family-filter .title {
	color: var(--bs-primary);
	text-align: center;
}

.products.family-filter .title h2 {
	padding: 1rem 4rem;
	margin: 1rem 0;
	border-right: 1px solid var(--bs-gray-300);
}

.products.family-filter .buttons .wrapper {
	display: flex;
	overflow-y: hidden;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	width: 100%;
	height: 100%;
	border-radius: 12px 12px 0px 0px;
}

.products.family-filter .buttons .wrapper .button {
	height: 100%;
	width: 200px;
	margin-left: 0rem;
	border: none;
	background-color: var(--bg-filter);
	color: var(--filter-color);
	font-weight: bold;
	transition: 0.15s all ease-in;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	transition: 0.3s all ease;
}

.products.family-filter .buttons .wrapper .button:hover {
	background-color: #cfe2ff;
	color: #7272ff;
	transition: 0.3s all ease;
}

.products.family-filter .buttons .wrapper .button.active {
	background-color: var(--bg-filter-active);
	color: #ffffff;
}

.products.family-filter .buttons .wrapper .button.active i {
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;
}

.products.product-grid {
	padding: 2rem 0;
	background-color: var(--bg-products);
}

.products.product-grid .filter div h5 {
	color: var(--filter-color);
}

.products.product-grid .filter div ul {
	list-style-image: none;
}

.products.product-grid .filter div ul li {
	padding: 0;
	border-bottom: none;
	margin-bottom: 5px;
}

.products.product-grid .filter div ul li a {
	color: var(--filter-link-color);
	text-decoration: none;
	transition: 0.2s color ease-in-out;
}

.products.product-grid .filter div ul li a:hover {
	color: var(--bs-primary);
}

/*
.products.product-grid .product-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content;
  gap: 20px;
  align-items: baseline;
}
*/

.product-card .card-img-top {
	border-radius: 12px 12px 0px 0px;
	aspect-ratio: 4/3;
	transform: scale(0.85);
	transition: 0.3s all ease;
}

.product-card .card-header {
	border-radius: 12px 12px 0px 0px;
	background-color: #ffffff;
}

.product-card:hover .card-header .card-img-top {
	transform: scale(0.95);
	border-radius: 12px 12px 0px 0px;
	transition: 0.3s all ease;
	overflow: hidden;
}

.product-card .card-body .price {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 1rem;
	min-height: 35px;
}

.product-card .card-body .price span {
	background-color: var(--bg-offer);
	padding: 0.15rem 0.5rem;
	border-radius: 26px;
	color: #ffffff;
	margin-right: 15px;
}

.product-card .card-body .price p {
	margin-bottom: 0;
	font-size: 22px;
	color: var(--bs-primary);
	margin-right: 15px;
}

.product-card .card-body .price del {
	color: var(--bs-gray-600);
	font-size: 14px;
}

.product-card .card-body .description {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.product-card .card-body a {
	width: 100%;
}

.single-product.description {
	margin: 4rem 0;
}

.single-product.description .title {
	margin-bottom: 2rem;
}

.single-product.description .title p {
	margin-bottom: 0;
	font-weight: bold;
}

.single-product.description .image {
	padding-right: 2rem;
}

.single-product.description .image div {
	width: 100%;
	height: 100%;
	box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.1607843137);
}

.single-product.description .image .taxonomies {
	width: auto;
	height: auto;
	box-shadow: none;
}

.single-product.description .image .taxonomies .badge {
	width: auto;
	height: auto;
}

.single-product.description .content {
	padding-left: 2rem;
}

.single-product.description .content h3 {
	width: 100%;
	color: var(--bs-primary);
	border-bottom: 1px solid var(--bs-primary);
	margin-bottom: 1rem;
	padding-bottom: 1rem;
}

.single-product.description .content .price {
	margin: 2rem 0;
}

.single-product.description .content a {
	padding-left: 2rem;
	padding-right: 2rem;
}

.single-product.more {
	margin: 4rem 0;
}

.single-product.more .title {
	color: var(--bs-primary);
	border-bottom: 1px solid var(--bs-primary);
	margin-bottom: 2rem;
}

.single-product.more .list ul {
	list-style-image: none;
	padding-left: 0;
	margin-bottom: 1rem;
}

.single-product.more .list ul li {
	padding: 0;
	border-bottom: none;
}

.single-product.more .list ul li:first-of-type {
	font-weight: bold;
}

.single-product.parts>div {
	padding: 2rem 0;
}

.single-product.parts>div.single .image {
	padding-right: 2rem;
	overflow: hidden;
}

.single-product.parts>div.single .image div {
	width: 100%;
	height: 200px;
}

.single-product.parts>div.single .content {
	padding-left: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.single-product.parts>div.double .row>div {
	display: flex;
}

.single-product.parts>div.double .row>div .image {
	width: 50%;
	padding-right: 1rem;
}

.single-product.parts>div.double .row>div .image div {
	width: 100%;
	height: 200px;
}

.single-product.parts>div.double .row>div .content {
	width: 50%;
	padding-left: 1rem;
}

/*
.single-product.parts > div.double .row > div:first-of-type {
  padding-right: 2rem;
}
.single-product.parts > div.double .row > div:last-of-type {
  padding-left: 2rem;
}
*/
.single-product.parts>div:nth-child(odd) {
	background-color: var(--bg-parts-single-product);
}

.single-product.parts>div:nth-child(even).single .content {
	order: 1;
}

.single-product.parts>div:nth-child(even).single .image {
	order: 2;
}

.single-product.downloads {
	margin: 4rem 0;
}

.single-product.downloads .title {
	color: var(--bs-primary);
	border-bottom: 1px solid var(--bs-primary);
}

.single-product.downloads .navs {
	border-right: 1px solid var(--bs-primary);
}

.single-product.downloads .navs .nav {
	padding: 1rem 0;
}

.single-product.downloads .navs .nav .nav-link {
	padding-left: 0;
	color: var(--filter-color);
}

.single-product.downloads .navs .nav .nav-link.active {
	font-weight: bold;
}

.single-product.downloads .content {
	padding: 1rem 2rem;
}

.single-product.downloads .content .tab-pane .row>div {
	padding: 1rem;
	display: flex;
	align-items: center;
}

.single-product.downloads .content .tab-pane .row>div i {
	font-size: 2.5rem;
	margin-right: 10px;
}

.single-product.downloads .content .tab-pane .row>div a {
	color: var(--filter-color);
	text-decoration: none;
	font-weight: bold;
}

.cart {
	margin: 4rem 0;
	min-height: calc(100vh - 110px - 93px - 8rem);
}

.cart .cart-titles {
	font-weight: bold;
	font-size: 20px;
	color: var(--bs-primary);
}

.cart .products-table {
	padding-right: 2rem;
}

.cart .products-table table thead {
	border-color: var(--bs-primary);
}

.cart .products-table table thead th:first-of-type {
	width: 70%;
}

.cart .products-table table thead th.quantity {
	text-align: center;
}

.cart .products-table table tbody {
	vertical-align: middle;
}

.cart .products-table table tbody tr {
	border-bottom: 1px solid rgba(55, 55, 250, 0.3);
}

.cart .products-table table tbody tr td {
	font-weight: 600;
	border-bottom: none;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cart .products-table table tbody tr td.product {
	display: flex;
	align-items: center;
	height: 100%;
}

.cart .products-table table tbody tr td.product a {
	color: var(--wp--preset--color--black);
}

.cart .products-table table tbody tr td.product div {
	width: 70px;
	height: 70px;
	margin-right: 15px;
}

.cart .products-table table tbody tr td input[type='number'],
.cart .products-table table tbody tr td input[type='text'] {
	text-align: center;
	border: 1px solid #707070;
	border-radius: 6px;
}

.cart .cart-summary {
	padding-left: 2rem;
}

.cart .cart-summary .buttons a {
	width: 49%;
}

.cart .cart-summary .buttons a:first-of-type {
	margin-right: 1%;
}

.cart .cart-summary .buttons a:last-of-type {
	margin-left: 1%;
}

.checkout {
	margin: 4rem 0;
	min-height: calc(100vh - 110px - 93px - 8rem);
}

.checkout .form {
	padding-right: 2rem;
}

.checkout .form .title {
	padding: 0.5rem 0;
	border-bottom: 1px solid var(--bs-primary);
}

.checkout .form input {
	border: 1px solid #707070;
	margin-bottom: 1rem;
	padding: 0.5rem;
}

.checkout .form input::placeholder {
	color: var(--placeholder-color);
}

.checkout .checkout-summary {
	padding-left: 2rem;
}

.checkout .checkout-summary .payment .title {
	padding-top: 0.5rem;
	margin-bottom: 0;
}

.checkout .checkout-summary .payment .form-check {
	border-bottom: 1px solid rgba(55, 55, 250, 0.3);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.checkout .checkout-summary .payment .form-check label {
	font-weight: 600;
}

.checkout .checkout-summary .payment .form-check p {
	margin-bottom: 0;
}

.checkout .checkout-summary .payment .form-check:last-of-type {
	border-bottom: none;
}

.checkout .checkout-summary a {
	padding-left: 2rem;
	padding-right: 2rem;
	width: fit-content;
}

.purchase-order {
	margin: 4rem 0;
	min-height: calc(100vh - 110px - 93px - 8rem);
}

.purchase-order .purchase-order-summary {
	padding-right: 2rem;
}

.purchase-order .purchase-order-summary p:not(.shop-titles) {
	font-weight: 600;
}

.purchase-order .purchase-order-summary .title {
	border-bottom: 1px solid var(--bs-primary);
	padding-bottom: 1rem;
}

.purchase-order .purchase-order-summary .resume {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--bs-primary);
	padding-bottom: 1rem;
}

.purchase-order .purchase-order-summary .summary {
	border-top: 1px solid var(--bs-primary);
	border-bottom: 1px solid var(--bs-primary);
	padding: 1rem 0;
}

.purchase-order .purchase-order-summary .summary>div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.purchase-order .purchase-order-summary .summary>div:last-of-type p {
	margin-bottom: 0;
}

.purchase-order .purchase-order-summary .total {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	margin-top: 1rem;
	background-color: var(--bg-filter-active);
	border-radius: 4px;
}

.purchase-order .purchase-order-summary .total p {
	margin-bottom: 0;
	color: #ffffff;
}

.purchase-order .purchase-order-status {
	padding-left: 2rem;
	padding-top: 2rem;
}

.purchase-order .purchase-order-status .status>div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.purchase-order .purchase-order-status .status>div p {
	font-weight: 600;
}

.purchase-order .purchase-order-status .payment {
	padding: 1rem 0;
	border-top: 1px solid var(--bs-primary);
	border-bottom: 1px solid var(--bs-primary);
}

.purchase-order .purchase-order-status .payment p:not(.shop-titles) {
	font-weight: 600;
}

.purchase-order .purchase-order-status .payment p:last-of-type {
	margin-bottom: 0;
}

.purchase-order .purchase-order-status .shipping {
	margin-top: 1rem;
}

.purchase-order .purchase-order-status .shipping>div {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.purchase-order .purchase-order-status .shipping>div p {
	font-weight: 600;
}

@media (min-width: 1400px) {
	.container {
		max-width: 1600px !important;
	}
}

@media (min-width: 1200px) {
	h1 {
		font-size: 3rem;
	}
}

@media (max-width: 991px) {
	.banner {
		height: 300px;
		background-position: left;
	}

	.swiper.navigation {
		padding: 0 3rem;
	}

	.swiper.navigation .slide-navigator button i {
		font-size: 15px;
	}

	.navbar .nav-link {
		font-size: 1rem;
		margin: 0;
		color: var(--bs-black);
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		text-align: center;
	}

	.navbar .nav-link:last-of-type {
		margin-right: 0;
		margin-left: 0;
	}

	footer .container {
		flex-direction: column;
	}

	footer .container .info {
		justify-content: center;
		flex-direction: column;
		margin-bottom: 1rem;
	}

	footer .container .info p {
		margin-left: 0;
		text-align: center;
	}

	footer .container .social {
		justify-content: center;
	}

	img[alt='logo'] {
		width: 200px;
	}

	.home.video-banner {
		height: calc(100vh - 104px);
	}

	.home.video-banner video {
		aspect-ratio: 9/16;
	}

	.home.video-banner .container .row>div {
		padding: 0 2rem;
	}

	.home.video-banner .container .row>div svg[alt='isologo'] {
		width: 50px;
		height: 50px;
	}

	.home.video-banner .container .row>div:last-of-type {
		display: flex;
		flex-direction: column-reverse;
		align-items: flex-end;
		justify-content: flex-end;
		text-align: end;
	}

	.home.video-banner .container .row>div:last-of-type button {
		width: fit-content;
	}

	.home.about-us {
		margin: 2rem 0;
	}

	.home.about-us .container-fluid .mission {
		padding: 0 1rem;
		margin: auto 0;
	}

	.home.about-us .container-fluid .mission-image img {
		width: 100%;
	}

	.home.products {
		margin: 2rem 0;
	}

	.home.products .container .title {
		margin-left: 0;
		margin-right: 0;
		padding: 0 1rem;
	}

	.home.products .container .title h1 {
		padding: 0 0.5rem;
		max-width: none;
	}

	.home.products .container .product-tabs {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.home.products .container .product-tabs .nav .nav-item {
		width: 85%;
		max-width: 85%;
	}

	.home.products .container .product-image {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.home.products .container .product-image .tab-content {
		padding: 2rem;
	}

	.home.news {
		margin: 2rem 0;
	}

	.home.weg {
		margin: 2rem 0;
	}

	.home.weg img {
		width: 100px;
		margin-bottom: 2rem;
	}

	.home.weg p {
		width: 100%;
		margin: 0 auto 2rem auto;
	}

	.home.services {
		margin: 2rem 0;
	}

	.home.services .title {
		text-align: center;
	}

	.home.services .title img {
		width: 100%;
	}

	.home.services .carousel {
		margin: 2rem 0;
		height: 250px;
	}

	.home.services .carousel .swiper .swiper-wrapper {
		padding-left: 2.5rem;
	}

	.home.services .carousel .swiper .swiper-wrapper .swiper-slide p {
		width: 90%;
	}

	.home.contact-banner {
		padding: 2rem 0;
	}

	.home.contact-banner .title {
		margin-bottom: 2rem;
	}

	.home.contact-banner .title svg {
		width: 50px;
		margin-top: 0;
	}

	.home.contact-us .form {
		padding: 1rem;
	}

	.home.contact-us .form p {
		margin-bottom: 2rem;
	}

	.home.contact-us .form form .form-control {
		margin-bottom: 1.5rem;
		border: none;
		border-radius: 0;
		border-bottom: 1px solid var(--bs-gray-300);
		padding-left: 0;
		padding-right: 0;
	}

	.home.contact-us .form form .form-control:focus {
		box-shadow: none;
	}

	.about-us.about-us-cards {
		margin: 4rem 0;
	}

	.about-us.about-us-cards .row {
		top: 0;
	}

	.about-us.about-us-cards .row .cards .card {
		width: 100%;
		margin-right: 0;
		margin-bottom: 4rem;
	}

	.about-us.about-us-cards .row .cards .card .card-body {
		padding-top: 4rem;
	}

	.about-us.about-us-cards .row .cards .card .card-body .title {
		color: var(--bs-primary);
		margin-bottom: 1rem;
	}

	.about-us.about-us-cards .row .cards .card:last-of-type {
		margin-bottom: 0;
	}

	.about-us.description {
		padding: 2rem 0;
	}

	.about-us.description p {
		margin-bottom: 0;
		font-size: 1rem;
	}

	.about-us.mission-vision {
		margin: 2rem 0;
	}

	.about-us.mission-vision .content {
		padding: 0 1rem;
		margin-bottom: 2rem;
	}

	.about-us.mission-vision .content svg {
		margin-top: 0;
		margin-right: 1rem;
	}

	.about-us.mission-vision .content:last-of-type {
		margin-bottom: 0;
	}

	.services.carousel {
		margin: 2rem 0;
	}

	.services.specialization {
		margin: 2rem 0;
	}

	.single-service.description {
		margin: 2rem 0;
	}

	.single-service.description .image {
		padding-right: initial;
	}

	.single-service.description .content {
		margin-top: 2rem;
		padding-left: initial;
	}

	.single-service.details {
		margin: 2rem 0;
	}

	.single-service.details .content {
		padding: 0 1rem;
	}

	.single-service.details .content div {
		margin-bottom: 2rem;
		display: flex;
		flex-direction: column;
	}

	.single-service.details .images {
		padding: 0 1rem;
		flex-direction: column;
	}

	.single-service.details .images img {
		width: 100%;
	}

	.contact-us {
		margin: 2rem 0;
	}

	.contact-us .form {
		padding-right: 0;
		margin-bottom: 2rem;
	}

	.contact-us .image img {
		height: auto;
		object-fit: contain;
	}

	.assistance {
		margin: 2rem 0;
	}

	.assistance .form {
		padding-right: 0;
		margin-bottom: 2rem;
	}

	.assistance .image img {
		border-radius: 12px;
	}

	.terms {
		margin: 2rem 0;
	}

	.terms .title svg {
		margin-right: 1rem;
		margin-top: 0;
	}

	.products.family-filter .title {
		margin-bottom: 1rem;
	}

	.products.family-filter .title h2 {
		padding: 2rem;
		margin: 0;
		border-right: none;
		border-bottom: 1px solid var(--bs-gray-300);
	}

	.products.family-filter .buttons .wrapper button {
		width: 200px;
		padding: 1.5rem;
		margin-left: 0;
		margin-right: 0.5rem;
	}

	.products.family-filter .buttons .wrapper button.active i {
		bottom: 5px;
	}

	.products.product-grid .product-cards {
		grid-template-columns: 1fr;
		gap: 20px;
	}

	.single-product.description {
		margin: 2rem 0;
	}

	.single-product.description .image {
		padding-right: 0;
		margin-bottom: 2rem;
	}

	/* .single-product.description .image div {
		height: 300px;
	} */

	.single-product.description .content {
		padding-left: 0;
	}

	.single-product.more {
		margin: 2rem 0;
	}

	.single-product.parts>div {
		padding: 2rem 0;
	}

	.single-product.parts>div.single .image {
		padding-right: 0;
		margin-bottom: 2rem;
	}

	.single-product.parts>div.single .content {
		padding-left: 0;
	}

	.single-product.parts>div.double .row>div {
		align-items: center;
	}

	.single-product.parts>div.double .row>div .image div {
		width: 100%;
		height: 100px;
	}

	.single-product.parts>div.double .row>div:first-of-type {
		padding-right: 0;
	}

	.single-product.parts>div.double .row>div:last-of-type {
		padding-left: 0;
	}

	.single-product.parts>div.double .row>div:last-of-type .image {
		order: 2;
	}

	.single-product.parts>div.double .row>div:last-of-type .content {
		order: 1;
	}

	.single-product.parts>div:nth-child(even).single .content {
		order: 2;
	}

	.single-product.parts>div:nth-child(even).single .image {
		order: 1;
	}

	.single-product.downloads {
		margin: 1rem 0;
	}

	.single-product.downloads .navs {
		border-right: none;
		border-bottom: 1px solid var(--bs-primary);
	}

	.single-product.downloads .content {
		padding: 1rem;
	}

	.single-product.downloads .content .tab-pane .row hr {
		display: none;
	}

	.single-product.downloads .content .tab-pane .row>div {
		padding: 1rem 0;
	}

	.single-product.downloads .content .tab-pane .row>div i {
		font-size: 2rem;
	}

	.cart {
		margin: 2rem 0;
		min-height: calc(100vh - 104px - 170px - 4rem);
	}

	.cart .cart-titles {
		font-weight: bold;
		font-size: 18px;
		color: var(--bs-primary);
	}

	.cart .products-table {
		padding-right: 0;
		order: 2;
	}

	.cart .products-table table thead th:first-of-type {
		width: auto;
	}

	.cart .products-table table tbody tr td {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-size: 0.8rem;
	}

	.cart .products-table table tbody tr td.product div {
		width: 50px;
		height: 50px;
	}

	.cart .cart-summary {
		padding-left: 0;
		order: 1;
		margin-bottom: 2rem;
	}

	.checkout {
		margin: 2rem 0;
		min-height: calc(100vh - 104px - 170px - 4rem);
	}

	.checkout .form {
		padding-right: 0;
	}

	.checkout .form .title {
		padding: 0.5rem 0;
		border-bottom: 1px solid var(--bs-primary);
	}

	.checkout .form input {
		border: 1px solid #707070;
		margin-bottom: 1rem;
		padding: 0.5rem;
	}

	.checkout .form input::placeholder {
		color: var(--placeholder-color);
	}

	.checkout .checkout-summary {
		padding-left: 0;
	}

	.checkout .checkout-summary .payment .form-check {
		border-bottom: 1px solid rgba(55, 55, 250, 0.3);
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.checkout .checkout-summary .payment .form-check label {
		font-weight: 600;
	}

	.checkout .checkout-summary .payment .form-check p {
		margin-bottom: 0;
	}

	.checkout .checkout-summary button {
		margin-top: 2rem;
		width: 100%;
	}

	.purchase-order {
		margin: 2rem 0;
		min-height: calc(100vh - 104px - 170px - 4rem);
	}

	.purchase-order .purchase-order-summary {
		padding-right: 0;
	}

	.purchase-order .purchase-order-status {
		padding-left: 0;
		padding-top: 2rem;
	}
}

@media (max-width: 576px) {
	.logo-head {
		max-width: 180px;
	}

	.card-body .card-text {
		font-size: 1rem !important;
		line-height: 1rem !important;
	}

	.btn.btn-primary.add_pedido {
		font-size: 0.8rem !important;
		line-height: 0.8rem !important;
	}

	.taxonomies div {
		display: inline-flex;
		padding: 0.3rem !important;
	}

	.banner {
		height: 200px;
		background-position: left;
	}

	.single-product.description .image div {
		height: auto;
		box-shadow: 0px 0px 0px #00000000;
	}

	.single-product.description .image {
		padding-right: 0.75rem;
		margin-bottom: 2rem;
	}

	.single-product.description .content {
		padding-left: 0.75rem;
	}

	.single-product.parts>div.single .content {
		padding-left: 0.75rem;
	}

	.single-product.parts>div.double .row>div:last-of-type .image {
		order: 1;
	}
}

.card-body .card-text {
	font-size: 1.5rem;
	line-height: 1.6rem;
}

.card-body .card-text p {
	font-size: 1rem;
	line-height: 1rem;
}

.card-body .card-text a {
	color: #000000;
}

.btn.btn-primary.add_pedido.remove_pedido {
	background: #fff;
	color: var(--bs-primary);
}

.taxonomies div {
	display: inline-flex;
	padding: 0.5rem;
}

.taxonomies div a {
	text-decoration: none;
}

.br-12 {
	border-radius: 12px !important;
}

.filter {
	cursor: pointer;
}

.pagination li {
	padding: 0.5rem 0rem;
	background-color: #FFFFFF;
	margin-right: 10px;
	border-radius: 5px;
	font-weight: 500;
	cursor: pointer;
	color: #3737fa;
}

.pagination li div {
	padding: 0.5rem 1rem;
	text-decoration: none;
	height: 10px;
	line-height: 10px;
}

.pagination li div.arrow {
	padding: 0.3rem 1rem;
}

.pagination li div.prev {
	padding: 0.5rem 0.8rem;
	text-decoration: none;
	line-height: 10px;
}

.pagination li div.next {
	padding: 0.5rem 0.8rem;
	text-decoration: none;
}

.pagination li span {
	padding: 0.5rem 1rem;
}

.pagination li span.dots {
	color: #0d6efd;
	cursor: default;
}

.pagination li.active {
	background-color: #3737fa;
	transform: scale(1.05);
}

.pagination li span.current {
	color: #FFFFFF;
	font-weight: 700;
}

#login_error {
	margin: 0 auto 20px;
	max-width: 400px;
	padding: 10px 15px;
	background-color: #f2dede;
	color: #a94442;
	width: 100%;
	max-width: 100%;
}

#login_error a {
	color: var(--dark) !important;
	font-weight: bolder;
}

#login_update {
	margin: 0 auto 10px;
	max-width: 400px;
	padding: 10px 15px;
	background-color: #76d576;
	color: white;
	width: 100%;
	max-width: 100%;
}

.box-shadow {
	box-shadow: 0px 3px 35px rgba(0, 0, 0, 0.0901960784);
}

.password-container {
	position: relative;
}

.wp-hide-pw {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	border: none;
	cursor: pointer;
	z-index: 5;
}

.wp-hide-pw .dashicons {
	font-size: 20px;
}

#password-strength-meter {
	width: 100%;
	height: 10px;
	margin-top: 10px;
}

#password-strength-message {
	display: block;
	margin-top: 5px;
	font-weight: bold;
}

#password-strength-meter {
	width: 100%;
	height: 10px;
	background: #e0e0e0;
	margin-top: 10px;
	border-radius: 5px;
	overflow: hidden;
}

#password-strength-meter div {
	height: 100%;
	transition: width 0.3s;
}

#password-strength-meter .strength-0 {
	width: 0%;
	background: red;
}

#password-strength-meter .strength-1 {
	width: 25%;
	background: orange;
}

#password-strength-meter .strength-2 {
	width: 50%;
	background: yellow;
}

#password-strength-meter .strength-3 {
	width: 75%;
	background: lightgreen;
}

#password-strength-meter .strength-4 {
	width: 100%;
	background: green;
}

#password-strength-message {
	display: block;
	margin-top: 5px;
	font-weight: bold;
}

/* Loader */
.overlay-loader {
	position: absolute;
	background-color: #ffffffb8;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	align-items: center;
	justify-content: center;
	display: none;
	transition: 0.1s all ease;
}

.overlay-loader.active {
	transition: 0.1s all ease;
	display: block;
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	min-height: 1px;
	min-width: 1px;
	opacity: 0;
	transition: 0.5s all ease;
}

.overlay-loader.active .loader {
	opacity: 1;
	transition: 0.5s all ease;
}

.loader {
	--main-color: #5E6FFF;
	--highlight-color: #CED3FF;
	--bullet: 25%;
	/* 50% for round or 0% for square*/
	--size: 7px;
	/* 7px is standard size */
}

.loader span {
	float: left;
	margin: 0 calc(var(--size) / 1.4);
	width: var(--size);
	height: var(--size);
	transition: transform .2s ease-in-out, background .2s ease;
	-webkit-transition: transform .2s ease-in-out, background .2s ease;
	-moz-transition: transform .2s ease-in-out, background .2s ease;
	animation-iteration-count: infinite;
	animation-name: highlight;
	animation-duration: 1.2s;
	border-radius: var(--bullet);
	background: var(--main-color);
}

.loader span:nth-of-type(1) {
	animation-delay: 0s;
}

.loader span:nth-of-type(2) {
	animation-delay: .1s;
}

.loader span:nth-of-type(3) {
	animation-delay: .2s;
}

.loader span:nth-of-type(4) {
	animation-delay: .3s;
}

.loader span:nth-of-type(5) {
	animation-delay: .4s;
}

.loader span:nth-of-type(6) {
	animation-delay: .5s;
}

.loader span:nth-of-type(7) {
	animation-delay: .6s;
}

.loader span:nth-of-type(8) {
	animation-delay: .7s;
}

.loader span:nth-of-type(9) {
	animation-delay: .8s;
}

.loader span:nth-of-type(10) {
	animation-delay: .9s;
}

.loader span:nth-of-type(11) {
	animation-delay: 1s;
}

.loader span:nth-of-type(12) {
	animation-delay: 1.1s;
}

@keyframes highlight {
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(2.1, 2.1);
		background: var(--highlight-color);
	}

	100% {
		transform: scale(1, 1);
	}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

input[type=number] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	margin: 0;
}

.btn-estado:not(.active) {
	border-color: var(--bs-card-border-color) !important;
	background-color: transparent !important;
}

.customize-support .offcanvas.offcanvas-end {
	top: 32px;
}

/* CRM */

.comentarios-editar-borrar {
	opacity: 0;
	transition: 0.3s all ease;
}

.comentario:hover .comentarios-editar-borrar {
	opacity: 1;
	transition: 0.3s all ease;
}

.comentarios-guardar {
	color: #000000;
	transition: 0.1s all ease;
	cursor: pointer;
}

.comentarios-editar {
	color: #000000;
	transition: 0.1s all ease;
	cursor: pointer;
}

.comentarios-borrar {
	color: #000000;
	transition: 0.1s all ease;
	cursor: pointer;
}

.comentarios-guardar:hover {
	color: #1ba70f;
	transition: 0.1s all ease;
}

.comentarios-editar:hover {
	color: #1ba70f;
	transition: 0.1s all ease;
}

.comentarios-borrar:hover {
	color: #d42020;
	transition: 0.1s all ease;
}

.linea-tabla:hover {
	background-color: #ebf7fa;
}

input.input-comment:disabled,
textarea.input-comment:disabled {
	background: #d2f4ea !important;
	color: #000;
}

textarea.input-comment {
	overflow: hidden;
	resize: vertical;
	width: 100%;
}

.floating-placeholder {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
	color: #aaa;
	transition: all 0.2s;
}