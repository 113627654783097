.panel {
    border: 1px solid;
    border-radius: 5px;
}

.panel-default {
    border-color: #f5f5f5;
}

.panel-primary {
    border-color: #337ab7;
}

.panel-success {
    border-color: #dff0d8;
}

.panel-info {
    border-color: #d9edf7;
}

.panel-warning {
    border-color: #fcf8e3;
}

.panel-danger {
    border-color: #f2dede;
}

/* Header */

.panel-header {
    font-weight: bold;
    height: 30px;
    padding-left: 10px;
    padding-top: 5px;
    border-bottom: none;
    font-size: 14px;
}

.panel-default>.panel-header {
    background-color: #f5f5f5;
    color: #333;
}

.panel-primary>.panel-header {
    background-color: #337ab7;
    color: #fff;
}

.panel-success>.panel-header {
    background-color: #dff0d8;
    color: #3c763d;
}

.panel-info>.panel-header {
    background-color: #d9edf7;
    color: #31708f;
}

.panel-warning>.panel-header {
    background-color: #fcf8e3;
    color: #8a6d3b;
}

.panel-danger>.panel-header {
    background-color: #f2dede;
    color: #a94442;
}

/* Body */

.panel-body {
    margin: 10px;
}